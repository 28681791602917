/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { createReducer, ActionType, createAction } from "typesafe-actions";

export const actions = {
  setSidebarVisible: createAction("DISPLAY_SET_SIDEBAR_VISIBLE")<boolean>(),
  setSidebarWidth: createAction("DISPLAY_SET_SIDEBAR_WIDTH")<number>()
};

type Actions = ActionType<typeof actions>;

export type DisplayServiceState = {
  sidebarVisible: boolean;
  sidebarWidth?: number;
};

export const initialState: DisplayServiceState = {
  sidebarVisible: true,
  sidebarWidth: undefined
};

export const displayServiceReducer = createReducer<
  DisplayServiceState,
  Actions
>(initialState)
  .handleAction(
    actions.setSidebarVisible,
    (state, action): DisplayServiceState => {
      return {
        ...state,
        sidebarVisible: action.payload
      };
    }
  )
  .handleAction(
    actions.setSidebarWidth,
    (state, action): DisplayServiceState => {
      return {
        ...state,
        sidebarWidth: action.payload
      };
    }
  );
