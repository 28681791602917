/**
 * Copyright 2021 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { Page } from "client/components/Page";
import { Box } from "client/components/Box";
import { Button } from "client/components/Button";
import { Typography } from "client/components/Typography";

import TracyImg from "client/views/common/Tracy";

export const LoginPage = ({ onLogin }: { onLogin: () => void }) => {
  useHotkeys("enter", onLogin);

  return (
    <Page centered height="100vh" width="100vw">
      <Box>
        <Box p={1} mb={4} display="flex" width="100%" justifyContent="center">
          <Box p={1} height={150} width={100}>
            <TracyImg />
          </Box>
          <Box p={1} height={150} display="flex" alignItems="center">
            <Typography variant="h3">opstrace</Typography>
          </Box>
        </Box>
        <Box p={1} display="flex" width="100%" justifyContent="center">
          <Box display="flex" alignItems="center" p={1}>
            <Button
              variant="contained"
              state="primary"
              size="large"
              onClick={onLogin}
            >
              Log In
            </Button>
          </Box>
          <Box display="flex" alignItems="center" p={1}>
            <Typography color="textSecondary"> Hit ENTER to log in.</Typography>
          </Box>
        </Box>
      </Box>
    </Page>
  );
};
