/**
 * Copyright 2021 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";

import { Box } from "client/components/Box";
import Typography from "client/components/Typography/Typography";
import { Tabs } from "client/components/Tabs";

import CortexConfig from "./cortex";
import OpstraceConfig from "./opstrace";

const ClusterConfig = () => {
  return (
    <>
      <Box pt={1} pb={4}>
        <Typography variant="h1">Configuration</Typography>
      </Box>
      <Tabs
        tabs={[
          {
            path: `/cluster/configuration/cortex`,
            title: "Cortex",
            component: CortexConfig
          },
          {
            path: `/cluster/configuration/opstrace`,
            title: "Opstrace",
            component: OpstraceConfig
          }
        ]}
      />
    </>
  );
};

export default ClusterConfig;
