/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { combineReducers } from "redux";
import { reducer as userReducer } from "./user/reducer";
import { reducer as tenantReducer } from "./tenant/reducer";
import { reducer as integrationReducer } from "./integration/reducer";
import { reducer as cortexConfigReducer } from "./cortex-config/reducer";
import { reducer as opstraceConfigReducer } from "./opstrace-config/reducer";
import { reducer as formReducer } from "./form/reducer";
import { notificationServiceReducer } from "client/services/Notification/reducer";

export const mainReducers = {
  users: userReducer,
  tenants: tenantReducer,
  integrations: integrationReducer,
  form: formReducer,
  cortex: cortexConfigReducer,
  opstrace: opstraceConfigReducer,
  notifications: notificationServiceReducer
};

export const mainReducer = combineReducers(mainReducers);
export type State = ReturnType<typeof mainReducer>;
