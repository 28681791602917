/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ITheme } from "client/themes";
import styled from "styled-components";

import { Box } from "../Box";

function spacing(theme: ITheme) {
  if (theme && typeof theme.spacing === "function") {
    return theme.spacing(1);
  }
  return 0;
}

const Container = styled(Box)`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${props => props.theme?.palette?.background?.default};
  display: flex;
  justifycontent: center;
  alignitems: center;
  padding: ${props => spacing(props.theme)}px;
`;

export default Container;
