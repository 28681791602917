/**
 * Copyright 2021 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { TableRow, TableCell, TableBody } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

type Props = {
  numberRows: number;
  numberColumns: number;
};
const SkeletonTableBody = ({ numberRows, numberColumns }: Props) => {
  return (
    <TableBody>
      {Array(numberRows)
        .fill(true)
        .map((_, idx) => (
          <TableRow key={idx}>
            {Array(numberColumns)
              .fill(true)
              .map((_, idx) => (
                <TableCell key={idx}>
                  <Skeleton />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </TableBody>
  );
};

export default SkeletonTableBody;
