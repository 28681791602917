/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

const modifiers: { [key: string]: string } = {
  // shiftKey
  shift: "⇧",
  // altKey
  alt: "⌥",
  option: "⌥",
  // ctrlKey
  ctrl: "⌃",
  control: "⌃",
  // metaKey
  cmd: "⌘",
  command: "⌘"
};

export const getPlatformMetaKey = () =>
  /Mac|iPod|iPhone|iPad/.test(
    typeof navigator !== "undefined" ? navigator.platform : ""
  )
    ? "cmd"
    : "ctrl";

export function replaceModKeyWithPlatformMetaKey(key: string) {
  return key.replace(/mod/g, getPlatformMetaKey());
}

export function getModifierSymbol(modifier: string) {
  return modifier in modifiers ? modifiers[modifier] : modifier;
}

export function getKeysFromKeybinding(keybinding: string) {
  const keysArray = replaceModKeyWithPlatformMetaKey(keybinding).split("+");
  return keysArray.map(key => getModifierSymbol(key));
}
