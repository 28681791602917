/**
 * Copyright 2021 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export default function makeDashboard(integrationId: string) {
  return {
    __inputs: [],
    __requires: [],
    annotations: {
      list: []
    },
    editable: false,
    gnetId: null,
    graphTooltip: 1,
    hideControls: false,
    id: null,
    links: [],
    refresh: "",
    panels: [
      {
        datasource: "$datasource",
        fieldConfig: {
          defaults: {
            custom: {}
          },
          overrides: []
        },
        gridPos: {
          h: 10,
          w: 24,
          x: 0,
          y: 0
        },
        id: 1,
        options: {
          showLabels: false,
          showTime: false,
          sortOrder: "Descending",
          wrapLogMessage: false
        },
        pluginVersion: "7.4.3",
        targets: [
          {
            expr: `{integration_id="${integrationId}"} |= "error"`,
            maxLines: 100,
            queryType: "randomWalk",
            refId: "A"
          }
        ],
        title: "Recent errors",
        type: "logs"
      },
      {
        aliasColors: {},
        bars: false,
        dashLength: 10,
        dashes: false,
        datasource: "$datasource",
        description: "",
        fieldConfig: {
          defaults: {
            custom: {}
          },
          overrides: []
        },
        fill: 1,
        fillGradient: 0,
        gridPos: {
          h: 10,
          w: 12,
          x: 0,
          y: 10
        },
        hiddenSeries: false,
        id: 2,
        legend: {
          avg: false,
          current: false,
          max: false,
          min: false,
          show: true,
          total: false,
          values: false
        },
        lines: true,
        linewidth: 1,
        nullPointMode: "null",
        options: {
          alertThreshold: true
        },
        percentage: false,
        pluginVersion: "7.4.3",
        pointradius: 2,
        points: false,
        renderer: "flot",
        seriesOverrides: [],
        spaceLength: 10,
        stack: false,
        steppedLine: false,
        targets: [
          {
            expr: `sum by (job) (rate({integration_id="${integrationId}"} |= "error" | line_format "{{.job}}"[1m]))`,
            legendFormat: "{{job}}",
            maxLines: null,
            refId: "A"
          }
        ],
        thresholds: [],
        timeFrom: null,
        timeRegions: [],
        timeShift: null,
        title: "Errors by pod",
        tooltip: {
          shared: true,
          sort: 0,
          value_type: "individual"
        },
        type: "graph",
        xaxis: {
          buckets: null,
          mode: "time",
          name: null,
          show: true,
          values: []
        },
        yaxes: [
          {
            format: "short",
            label: "lines/s",
            logBase: 1,
            max: null,
            min: null,
            show: true
          },
          {
            format: "short",
            label: null,
            logBase: 1,
            max: null,
            min: null,
            show: true
          }
        ],
        yaxis: {
          align: false,
          alignLevel: null
        }
      },
      {
        aliasColors: {},
        bars: false,
        dashLength: 10,
        dashes: false,
        datasource: "$datasource",
        description: "",
        fieldConfig: {
          defaults: {
            color: {},
            custom: {},
            thresholds: {
              mode: "absolute",
              steps: []
            }
          },
          overrides: []
        },
        fill: 1,
        fillGradient: 0,
        gridPos: {
          h: 10,
          w: 12,
          x: 12,
          y: 10
        },
        hiddenSeries: false,
        id: 3,
        legend: {
          avg: false,
          current: false,
          max: false,
          min: false,
          show: true,
          total: false,
          values: false
        },
        lines: true,
        linewidth: 1,
        nullPointMode: "null",
        options: {
          alertThreshold: true
        },
        percentage: false,
        pluginVersion: "7.4.3",
        pointradius: 2,
        points: false,
        renderer: "flot",
        seriesOverrides: [],
        spaceLength: 10,
        stack: false,
        steppedLine: false,
        targets: [
          {
            expr: `sum by (namespace, controller) (rate(({integration_id="${integrationId}"})[1m]))`,
            legendFormat: "{{namespace}}/{{controller}}",
            maxLines: null,
            refId: "A"
          }
        ],
        thresholds: [],
        timeFrom: null,
        timeRegions: [],
        timeShift: null,
        title: "Errors by statefulset/daemonset/replicaset",
        tooltip: {
          shared: true,
          sort: 0,
          value_type: "individual"
        },
        type: "graph",
        xaxis: {
          buckets: null,
          mode: "time",
          name: null,
          show: true,
          values: []
        },
        yaxes: [
          {
            decimals: null,
            format: "short",
            label: "lines/s",
            logBase: 1,
            max: null,
            min: "0",
            show: true
          },
          {
            format: "short",
            label: null,
            logBase: 1,
            max: null,
            min: null,
            show: true
          }
        ],
        yaxis: {
          align: false,
          alignLevel: null
        }
      },
      {
        datasource: "$datasource",
        fieldConfig: {
          defaults: {
            custom: {}
          },
          overrides: []
        },
        gridPos: {
          h: 10,
          w: 24,
          x: 0,
          y: 20
        },
        id: 4,
        options: {
          showLabels: false,
          showTime: false,
          sortOrder: "Descending",
          wrapLogMessage: false
        },
        pluginVersion: "7.4.3",
        targets: [
          {
            expr: `{integration_id="${integrationId}"}`,
            maxLines: 100,
            queryType: "randomWalk",
            refId: "A"
          }
        ],
        title: "Recent logs",
        type: "logs"
      },
      {
        aliasColors: {},
        bars: false,
        dashLength: 10,
        dashes: false,
        datasource: "$datasource",
        description: "",
        fieldConfig: {
          defaults: {
            color: {},
            custom: {},
            thresholds: {
              mode: "absolute",
              steps: []
            }
          },
          overrides: []
        },
        fill: 1,
        fillGradient: 0,
        gridPos: {
          h: 10,
          w: 12,
          x: 0,
          y: 30
        },
        hiddenSeries: false,
        id: 5,
        legend: {
          avg: false,
          current: false,
          max: false,
          min: false,
          show: true,
          total: false,
          values: false
        },
        lines: true,
        linewidth: 1,
        nullPointMode: "null",
        options: {
          alertThreshold: true
        },
        percentage: false,
        pluginVersion: "7.4.3",
        pointradius: 2,
        points: false,
        renderer: "flot",
        seriesOverrides: [],
        spaceLength: 10,
        stack: false,
        steppedLine: false,
        targets: [
          {
            expr: `sum by (job) (rate(({integration_id="${integrationId}"})[1m]))`,
            legendFormat: "{{job}}",
            maxLines: null,
            refId: "A"
          }
        ],
        thresholds: [],
        timeFrom: null,
        timeRegions: [],
        timeShift: null,
        title: "Logs by pod",
        tooltip: {
          shared: true,
          sort: 0,
          value_type: "individual"
        },
        type: "graph",
        xaxis: {
          buckets: null,
          mode: "time",
          name: null,
          show: true,
          values: []
        },
        yaxes: [
          {
            format: "short",
            label: "lines/s",
            logBase: 1,
            max: null,
            min: null,
            show: true
          },
          {
            format: "short",
            label: null,
            logBase: 1,
            max: null,
            min: null,
            show: true
          }
        ],
        yaxis: {
          align: false,
          alignLevel: null
        }
      },
      {
        aliasColors: {},
        bars: false,
        dashLength: 10,
        dashes: false,
        datasource: "$datasource",
        description: "",
        fieldConfig: {
          defaults: {
            color: {},
            custom: {},
            thresholds: {
              mode: "absolute",
              steps: []
            }
          },
          overrides: []
        },
        fill: 1,
        fillGradient: 0,
        gridPos: {
          h: 10,
          w: 12,
          x: 12,
          y: 30
        },
        hiddenSeries: false,
        id: 6,
        legend: {
          avg: false,
          current: false,
          max: false,
          min: false,
          show: true,
          total: false,
          values: false
        },
        lines: true,
        linewidth: 1,
        nullPointMode: "null",
        options: {
          alertThreshold: true
        },
        percentage: false,
        pluginVersion: "7.4.3",
        pointradius: 2,
        points: false,
        renderer: "flot",
        seriesOverrides: [],
        spaceLength: 10,
        stack: false,
        steppedLine: false,
        targets: [
          {
            expr: `sum by (namespace) (rate(({integration_id="${integrationId}"})[1m]))`,
            legendFormat: "{{namespace}}",
            maxLines: null,
            refId: "A"
          }
        ],
        thresholds: [],
        timeFrom: null,
        timeRegions: [],
        timeShift: null,
        title: "Logs by namespace",
        tooltip: {
          shared: true,
          sort: 0,
          value_type: "individual"
        },
        type: "graph",
        xaxis: {
          buckets: null,
          mode: "time",
          name: null,
          show: true,
          values: []
        },
        yaxes: [
          {
            decimals: null,
            format: "short",
            label: "lines/s",
            logBase: 1,
            max: null,
            min: "0",
            show: true
          },
          {
            format: "short",
            label: null,
            logBase: 1,
            max: null,
            min: null,
            show: true
          }
        ],
        yaxis: {
          align: false,
          alignLevel: null
        }
      }
    ],
    schemaVersion: 27,
    style: "dark",
    tags: ["kubernetes-integration"],
    templating: {
      list: [
        {
          current: {
            text: "Loki",
            value: "Loki"
          },
          hide: 0,
          label: null,
          name: "datasource",
          options: [],
          query: "loki",
          refresh: 1,
          regex: "",
          type: "datasource"
        }
      ]
    },
    time: {
      from: "now-1h",
      to: "now"
    },
    timepicker: {
      refresh_intervals: [
        "5s",
        "10s",
        "30s",
        "1m",
        "5m",
        "15m",
        "30m",
        "1h",
        "2h",
        "1d"
      ],
      time_options: ["5m", "15m", "1h", "6h", "12h", "24h", "2d", "7d", "30d"]
    },
    timezone: "",
    title: "Kubernetes / Logs summary",
    uid: `sum-${integrationId}`,
    version: 0
  };
}

export type Dashboard = ReturnType<typeof makeDashboard>;
