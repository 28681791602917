/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import styled from "styled-components";

import NotFoundComponent, {
  NotFoundProps
} from "client/components/Error/NotFound";
import { Page } from "client/components/Page";

// Force this component to cover over any existing UI elements
// by setting position to absolute and background to something opaque
const StyledPage = styled(Page)`
  background: ${props => props.theme.palette.background.default};
`;

const NotFound = (props: NotFoundProps) => {
  return (
    <StyledPage centered>
      <NotFoundComponent {...props} />
    </StyledPage>
  );
};

export default NotFound;
