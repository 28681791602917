/**
 * Copyright 2020 Opstrace, Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useEffect } from "react";
import { createSelector } from "reselect";
import { useDispatch, useSelector, State } from "state/provider";
import {
  subscribeToCortexConfig,
  unsubscribeFromCortexConfig
} from "../actions";
import getSubscriptionID from "state/utils/getSubscriptionID";

export const getCortexConfig = (state: State) => state.cortex;

export const getCortexConfigLoaded = createSelector(
  getCortexConfig,
  cortex =>
    !cortex.loadingConfig &&
    !cortex.loadingRecognizedRuntimeConfig &&
    !cortex.loadingRuntimeConfig
);

export function useCortexConfigLoaded() {
  return useSelector(getCortexConfigLoaded);
}

/**
 * Subscribes to cortex config and will update on
 * any changes. Automatically unsubscribeFromCortexConfigs
 * on unmount.
 */
export default function useCortexConfig() {
  const cortexConfig = useSelector(getCortexConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    const subId = getSubscriptionID();
    dispatch(subscribeToCortexConfig(subId));
    return () => {
      dispatch(unsubscribeFromCortexConfig(subId));
    };
  }, [dispatch]);

  return cortexConfig;
}
